<template>
  <div class="d-flex justify-content-end mb-3">
    <div
      class="
        dataTables_paginate
        paging_simple_numbers
        d-flex
        align-items-center
      "
    >
      <ul class="pagination pagination-rounded mb-0" v-if="paginate">
        <!-- pagination -->
        <b-pagination
          v-if="totalRows > perPage"
          v-bind="$attrs"
          v-on="$listeners"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </ul>

      <p class="mb-0 ml-4" v-if="showTotal">
        {{
          $t(paginate ? "items-count" : "record-count", {
            count: firstRecordNumber(),
            total: formatNumber(totalRows)
          })
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    recordCount: Number,
    totalRows: Number,
    perPage: null,
    paginate: Boolean,
    showTotal: Boolean,
    currentPage: Number
  },
  methods: {
    firstRecordNumber() {
      const count = (this.$props.currentPage - 1) * this.$props.perPage;
      const first = Math.min(count + 1, this.$props.totalRows);

      const second = Math.min(
        this.$props.perPage * this.$props.currentPage,
        this.$props.totalRows
      );

      return this.formatNumber(first) + " - " + this.formatNumber(second);
    }
  }
};
</script>

<style></style>
